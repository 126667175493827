import React from 'react';
import { Navigation, Route, Routes } from '@models/navigation';
import { RouteComponentProps } from '@reach/router';

export const RoutesContext = React.createContext<
	Partial<
		RouteComponentProps & {
			routes: Routes;
			routeStack: Routes;
			currentRouteItem?: Route | null;
			flatRoutes: Routes;
			navigation: Navigation;
		}
	>
>({
	routes: [],
	routeStack: [],
	currentRouteItem: null,
	flatRoutes: [],
	navigation: [],
});

export const HeaderHeightContext = React.createContext<Partial<{ height: number | null }>>({
	height: null,
});
